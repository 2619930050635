<template>
  <el-dialog
    v-model="centerDialogVisible"
    width="35%"
    align-center
    center
    :show-close="false"
  >
    <template #header>
      <div class="header">
        <span>领取优惠券</span>
        <img
          src="./img/close.png"
          alt=""
          @click="centerDialogVisible = false"
        />
      </div>
    </template>
    <div class="coupon-detail" v-for="item in couponList">
      <!--<img src="./img/received.png" alt="" class="coupon-icon">-->
      <template style="display: flex" @click="toCouponDetail(item.id)">
        <div class="box-left">
          <div>
            <span>￥</span>
            <span>{{ item.amount }}</span>
          </div>
          <div>满{{ item.amountOver }}元可用</div>
        </div>
        <div class="box-center">
          <span>{{ item.couponName }}</span>
          <span>{{ item.type == 0 ? "代金券" : "" }}</span>
          <span>{{ item.validityStart }} 至 {{ item.validityEnd }}</span>
        </div>
      </template>
      <div class="box-right">
        <button
          class="box-right-button"
          @click="cliceCoupon(item.couponNumber)"
		  :key="productName"
		  v-track="{event:'立即领取',page:productName+'的商品详情'}"
        >
          立即领取
        </button>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref } from "vue";
import { couponQueryList, couponReceive } from "@/api/coupon";
import { useRouter } from "vue-router";
const route = useRouter();
import emitter from "@/utils/eventBus";
import { ElMessage } from "element-plus";

const couponList = ref([]); // 优惠券数据
const centerDialogVisible = ref(false); // 控制优惠券领取弹窗
const props=defineProps(['productName'])

emitter.on("couponPopupNew", () => {
  centerDialogVisible.value = true;
  //couponList.value = val.couponList;

  // 查询优惠券
  couponQueryList()
    .then((res) => {
      if (res.code == 200) {
        couponList.value = res.data;
      }
    })
    .catch((err) => {
      console.log(err);
    });
});

const toCouponDetail = (id) => {
	router.push({
		name:'couponDetail',
		query:{
			id: id,
			receiveId:1,
			status: 1
		}
	})
};

// 点击优惠券领取使用
const cliceCoupon = (couponNumber) => {
  couponReceive(couponNumber)
    .then((res) => {
      if (res.code == 200) {
        ElMessage({
          message: "领取成功！",
          type: "success",
        });
        setTimeout(() => {
          centerDialogVisible.value = false;
        }, 500);
      } else {
        ElMessage({
          message: res.message,
          type: "error",
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
</script>

<style scoped lang="less">
.header {
  display: flex;
  justify-content: space-between;
  span {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 18px;
    color: #111111;
  }
  img {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}
.coupon-detail {
  display: flex;
  width: 608px;
  height: 112px;
  margin: 0 auto;
  margin-bottom: 12px !important;
  background-image: url("./img/background.png");
  background-size: cover;
  .coupon-icon {
    position: absolute;
    margin-left: 16px;
    margin-top: -8px;
    width: 39px;
    height: 39px;
  }
  .box-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 130px;
    cursor: pointer;
    div:nth-child(1) {
      span:nth-child(1) {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #ce1200;
      }
      span:nth-child(2) {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 22px;
        color: #ce1200;
      }
    }
    div:nth-child(2) {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #ce1200;
    }
  }
  .box-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 32px;
    width: 306px;
    cursor: pointer;
    span:nth-child(1) {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 15px;
      color: #111111;
    }
    span:nth-child(2) {
      margin: 5px 0;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
    }
    span:nth-child(3) {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #999999;
    }
  }
  .box-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 172px;
    .box-right-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90px;
      height: 30px;
      background: #ce1200;
      border: none;
      border-radius: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 13px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
